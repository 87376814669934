import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundMission from "../components/background/BackgroundMission"
import ContactUs from "../components/ContactUs"

const Certification = () => (
  <Layout>
    <PageHeader title="NFVI Certification" subtitle="Certify your cloud platform using industry standard benchmarks"/>
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-right width-100">
              <BackgroundMission />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Our Representation</h2>
            </div>
            <p className="paragraph-3">
            VoerEir is a member of Linux Foundation and Linux foundation
              Networking. We have representatives in the latter's "Compliance
              and Verification Comity" (CVC).
            </p>
            <p className="paragraph-3">
            VoerEir’s prime focus is to work within OPNFV, for which our head
              in India Arif Khan has an ambassador role. Within OPNFV we
              contribute to OVP (OPNFV Verification Platform) and Yardstick,
              their working group for performance measurement.
            </p>
            <p className="paragraph-3 mb-4">
            This work gives us a unique position to do vendor independent NFVI
              Certifications as a service. VoerEir’s test system, Touchstone, is
              delivered with thousands of pre-integrated Test Cases. This
              includes Test Suites for both OpenStack and OPNFV Certification.
              These are used for our OpenSource community certification service.
              Touchstone also comes with pre-configured Test Suites to measure
              around 200 NFVI-KPIs.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Recognition</h2>
            </div>
          </div>
          <div className="col-lg-12">
          <p className="paragraph-3 mb-4">
          Our test suites have been used for validating quite a few
              operators NFVI, coming from several different vendors. Based on
              this experience VoerEir has compiled an Industry Benchmark. In
              Touchstone, VoerEir has also configured a Test Suites for
              Robustness and Stability to secure that an NFVI is ready for
              serving as a telecom Platform in operation. VoerEir offers a
              service which Certifies, Benchmarks and Validates for operation
              readiness, an operator's NFVI.
            </p>
          </div>
          <div className="col-lg-12">
          <p className="paragraph-3 mb-4">
          It is this cloud infrastructure we call "Network Function Virtualization Infrastructure" (NFVI). NFVIs are today typically based OpenStack cloud management SW and KVM as hypervisors, eventually moving to Kubernetes and docker containers. An NFVI solution is a quite complex solution, containing SW from between 15 and 20 different Opensource communities, and HW from several different vendors. Solution integration is, therefore, a substantial task, and the resulting Infrastructures can have quite a significant variation both in functionality and performance characteristics.
            </p>
            <p className="paragraph-3">
            This is done by connecting the NFVI to a local or remote installed
              Touchstone system and execute all these Test Suites. Included in
              the Service is also an investigation around found weaknesses.
              E.g., Deep analysis of test reports, Test Cases can be re-run with
              slightly changed parameters to better understand route cause to
              Bottlenecks, or, e.g., stochastically occurring disturbances
              influencing test results.
            </p>
            <p className="paragraph-3">
            The result from the Service is a ~50 page report, showing all test
              results, pointing at possible root causes, and explaining
              potential impact for different kinds of VNFs
            </p>

          </div>
        </div>
      </div>
    </section>
    <ContactUs/>
  </Layout>
)

export default Certification
